import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ENRICHED_UNIQUE_CARDS_PATH } from '../constants/constants';
import { TABS_MARKET } from '../constants/tabs';
import useLoadCardsFromJson from '../hooks/useLoadCardsFromJson';
import BackButton from '../parts/BackButton';
import CardList from '../parts/CardList';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import Tabs from '../parts/Tabs';
import { RootState } from '../store/store';

const UniqueMarket: React.FC = () => {
  const { t } = useTranslation();
  const { allCards, loading, error } = useLoadCardsFromJson(
    ENRICHED_UNIQUE_CARDS_PATH
  );
  const [selectedTab, setSelectedTab] = useState<string>(
    TABS_MARKET.MARKET.key
  );

  const user = useSelector((state: RootState) => state.user);

  const myFavoriteCard = useSelector(
    (state: RootState) => state.collectionCards.favoriteCards
  );

  document.title = t('navigation.market') + ' | Altverse';

  if (loading) {
    return <Loading label={t('loading.loadingCards')} />;
  }

  if (error) {
    return <p className="text-xl text-red-500">{error}</p>;
  }

  return (
    <Layout>
      <div className="space-y-8">
        <BackButton to="market" title={t('market.unique.title')} />
        {allCards && allCards.length > 0 ? (
          <div className="space-y-8 text-center">
            <h2 className="font-chillaxBold md:text-2xl">
              {t('status.lastUpdate', { date: `31-01-2025`, time: '11:00' })}
            </h2>
            <div>
              <Tabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                tabs={TABS_MARKET}
                full
              />
              {selectedTab === TABS_MARKET.MARKET.key && (
                <CardList
                  cards={allCards}
                  asFavorite
                  needBetaTester={!user?.roles?.includes('market')}
                />
              )}
              {selectedTab === TABS_MARKET.FAVORITE.key && (
                <CardList
                  cards={myFavoriteCard || []}
                  asFavorite
                  needBetaTester={!user?.roles?.includes('market')}
                />
              )}
            </div>
          </div>
        ) : (
          <p>{t('friendship.noCardsFound')}</p>
        )}
      </div>
    </Layout>
  );
};

export default UniqueMarket;
