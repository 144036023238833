import React, { useEffect, useState } from 'react';

import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import CardFilters from './CardFilters';
import SealedSummary from './SealedSummary';
import Card from '../components/Card';
import Pagination from '../components/Pagination';
import Separator from '../components/Separator';
import { RootState } from '../store/store';
import { FullCard } from '../types/altCard';
import { isUnique } from '../utils/cardUtilities';
import { linkTo } from '../utils/linkTo';

interface CardListProperties {
  cards: FullCard[];
  setSelectedCards?: (cards: FullCard[]) => void;
  selectedCards?: FullCard[];
  isSealed?: boolean;
  isExchange?: boolean;
  isDonation?: boolean;
  isExport?: boolean;
  isExportSealed?: boolean;
  isDeck?: boolean;
  isClose?: boolean;
  isDeckBuilder?: boolean;
  asFavorite?: boolean;
  needBetaTester?: boolean;
  smallFilter?: boolean;
  goTo?: string;
  faction?: string;
  showCollectionFilter?: boolean;
  addDeckCard?: (card: FullCard) => void;
  addCardBack?: (card: FullCard) => void;
  handleClose?: () => void;
  quantity?: boolean;
}

const getGridStyle = (numberCols?: number) => {
  if (numberCols) {
    return {
      display: 'grid',
      gridTemplateColumns: `repeat(${numberCols}, minmax(0, 1fr))`,
      gap: '2.5rem',
    };
  }
};

const CardList: React.FC<CardListProperties> = ({
  cards,
  setSelectedCards,
  selectedCards,
  isSealed,
  isExchange,
  isDonation,
  isDeck,
  isExport,
  isExportSealed,
  isClose,
  isDeckBuilder,
  asFavorite,
  needBetaTester,
  smallFilter,
  goTo,
  faction,
  showCollectionFilter,
  addDeckCard,
  addCardBack,
  handleClose,
  quantity = false,
}) => {
  const [filteredCards, setFilteredCards] = useState<FullCard[]>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const columns = useSelector((state: RootState) => state.sidePanel.columns);

  const isFoilEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFoilEnabled
  );

  const cardsPerPage = useSelector(
    (state: RootState) => state.sidePanel.cardsPerPage
  );

  const isVisualPage = !isExchange && !isDonation && !isSealed && isFoilEnabled;
  const needPagination = !isDeckBuilder && !isSealed;

  const handleCardSelect = (card: FullCard) => {
    const isSelected = selectedCards?.some(
      (c) => c.reference === card.reference
    );
    let newSelectedCards;

    if (selectedCards) {
      if (isSelected) {
        newSelectedCards = selectedCards?.filter(
          (c) => c.reference !== card.reference
        );
      } else {
        const cardWithQuantity = { ...card, nbSelected: 1 };
        newSelectedCards = [...selectedCards, cardWithQuantity];
      }
    }

    setSelectedCards && newSelectedCards && setSelectedCards(newSelectedCards);
  };

  const handleQuantityChange = (cardId: string, quantity: number) => {
    const updatedSelectedCards = selectedCards?.map((card) =>
      card.reference === cardId ? { ...card, nbSelected: quantity } : card
    );

    setSelectedCards &&
      updatedSelectedCards &&
      setSelectedCards(updatedSelectedCards);
  };

  const indexOfLastCard = currentPage * (cardsPerPage || 50);
  const indexOfFirstCard = indexOfLastCard - (cardsPerPage || 50);
  const currentCards = needPagination
    ? filteredCards.slice(indexOfFirstCard, indexOfLastCard)
    : filteredCards;

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (cardsPerPage) {
      setCurrentPage(1);
    }
  }, [cardsPerPage, cards]);

  const totalPages = Math.ceil(filteredCards.length / (cardsPerPage || 50));

  return (
    <div
      className={`container mx-auto p-4 ${
        isSealed ? 'space-y-4' : 'space-y-4 md:space-y-24'
      }`}
    >
      <div className="flex flex-col items-start space-y-1">
        <CardFilters
          cards={cards}
          filteredCards={filteredCards}
          showCollectionFilter={showCollectionFilter}
          setFilteredCards={setFilteredCards}
          isDonation={isDonation}
          isExport={isExport}
          isExportSealed={isExportSealed}
          isClose={isClose}
          isSealed={isSealed}
          smallFilter={smallFilter}
          goTo={goTo}
          faction={faction}
          handleClose={handleClose}
        />
      </div>

      {isSealed && <SealedSummary cards={filteredCards} isDeck={isDeck} />}
      <div>
        {needPagination && (
          <div className="relative h-fit mt-6 md:mt-0">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
            <Separator desktop />
          </div>
        )}
        {needBetaTester && (
          <p className="w-full mt-6 text-red-500 font-chillaxBold">
            <Trans
              i18nKey="errors.accessmMarket"
              components={{
                LinkTo: linkTo({
                  dest: 'https://discord.gg/8yvbc3bk8h',
                }),
                Bold: <strong />,
              }}
            />
          </p>
        )}
        <div
          style={getGridStyle(columns)}
          className={`${!isSealed && 'py-10'} ${
            !columns &&
            `grid ${
              isSealed
                ? 'grid-cols-2 md:grid-cols-3'
                : 'grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
            } gap-10 md:grid-cols-3`
          }`}
        >
          {!needBetaTester &&
            (isSealed ? filteredCards : currentCards).map((card, index) => (
              <div className="relative" key={`${card.reference}-${index}`}>
                <div
                  onClick={
                    selectedCards ? () => handleCardSelect(card) : undefined
                  }
                  className={`${selectedCards ? 'cursor-pointer' : ''}`}
                >
                  <Card
                    card={card}
                    freeze={!isVisualPage}
                    asFavorite={asFavorite}
                    selected={selectedCards?.some(
                      (c) => c.reference === card.reference
                    )}
                    addDeckCard={
                      addDeckCard ? () => addDeckCard(card) : undefined
                    }
                    addCardBack={
                      addCardBack ? () => addCardBack(card) : undefined
                    }
                    noClick={selectedCards ? true : false}
                  />
                </div>
                {quantity &&
                  !isUnique(card.reference) &&
                  selectedCards?.some(
                    (c) => c.reference === card.reference
                  ) && (
                    <div className="absolute bottom-0 left-0 flex justify-center items-center w-fit">
                      <input
                        id={`quantity-${card.reference}`}
                        type="number"
                        min="1"
                        value={
                          selectedCards.find(
                            (c) => c.reference === card.reference
                          )?.nbSelected
                        }
                        onChange={(event) =>
                          handleQuantityChange(
                            card.reference,
                            Number.parseInt(event.target.value, 10)
                          )
                        }
                        className="block max-w-14 shadow sm:text-sm rounded-md p-1 border-2 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                      />
                    </div>
                  )}
              </div>
            ))}
        </div>
        {needPagination && (
          <div className="relative mt-6">
            <Separator />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              isBottom
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardList;
