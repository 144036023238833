import React from 'react';

import { useTranslation } from 'react-i18next';

import ModalTemplate from '../../components/ModalTemplate';
import { TeamDetail } from '../../types/team';

interface MembersModalProperties {
  isOpen: boolean;
  team: TeamDetail;
  closeModal: () => void;
}

const MembersModal: React.FC<MembersModalProperties> = ({
  team,
  isOpen,
  closeModal,
}) => {
  const { t } = useTranslation();

  return (
    <ModalTemplate isOpen={isOpen} closeModal={closeModal} width="w-fit">
      <div className="space-y-2">
        <h2 className="text-xl font-chillaxBold">{t('teams.members')}</h2>
        <ul className="list-disc list-inside">
          {team?.members?.map((member) => {
            return (
              <li className="font-chillaxMedium" key={member.idUser}>
                {team?.createdBy === member.idUser
                  ? `${member.pseudo} (admin)`
                  : member.pseudo}
              </li>
            );
          })}
        </ul>
      </div>
    </ModalTemplate>
  );
};

export default MembersModal;
