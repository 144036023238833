import { useState } from 'react';

import { t } from 'i18next';

import { useUniqueCards } from './api/useUniques';
import { fetchUniqueCards } from '../api/fetchUniqueCards';
import { FullCard } from '../types/altCard';

const useRefetchCards = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLabel, setLoadingLabel] = useState<string>(
    t('loading.loadingCards')
  );
  const { updateUniqueCards } = useUniqueCards();

  const onProgressUpdate = (advancement: string) => {
    setLoadingLabel(`${t('loading.loadingCards')} ${advancement}`);
  };

  const refetchCards = async (cards: FullCard[]) => {
    if (cards.length === 0) return;

    setLoading(true);

    const { publicUniqueCards } = await fetchUniqueCards({
      uniqueCardStats: cards.map((card) => ({
        reference: card.reference,
        count: 1,
      })),
      isPublic: true,
      onProgressUpdate,
    });

    if (publicUniqueCards.length > 0) {
      for (const uniqueCard of publicUniqueCards) updateUniqueCards(uniqueCard);
    }

    setLoading(false);
  };

  return {
    loadingLabel,
    loading,
    setLoading,
    refetchCards,
  };
};

export default useRefetchCards;
