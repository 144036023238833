import {
  CARD_TYPE,
  FACTIONS,
  PARTICULE_ID_REGEX,
  PRIMARY_SORT_MODE,
  RARITY,
  SECONDARY_SORT_MODE,
  SET,
  SORT_DIRECTION,
} from '../constants/constants';
import { FullCard } from '../types/altCard';
import { Exchange } from '../types/exchange';
import { Member } from '../types/team';

interface SimpleCard {
  reference: string;
  quantity: number;
}

interface SummaryProperties {
  total: number;
  heroes: number;
  characters: number;
  spells: number;
  permanents: number;
  common: number;
  rare: number;
  unique: number;
  yz: number;
  ax: number;
  br: number;
  mu: number;
  ly: number;
  or: number;
  nbFaction: number;
}

export const isUnique = (reference: string): boolean => {
  return !!reference?.includes('_U_');
};

export const parseCardList = (input: string): SimpleCard[] => {
  return input.split('\n').map((line) => {
    const [quantity, reference] = line.split(' ');
    return { reference, quantity: Number.parseInt(quantity) };
  });
};

export const getTotalNumberOfCards = (cards: FullCard[]): number => {
  return cards.reduce((total, card) => total + (card.inMyCollection ?? 0), 0);
};

export const getMissingCards = (
  myCards: FullCard[],
  allCards: FullCard[],
  rareQuantity: number,
  commonQuantity: number,
  raritiesToInclude?: string[]
): FullCard[] => {
  // Créer un dictionnaire des quantités de cartes que nous possédons
  const myCardQuantities: Record<string, number> = {};

  for (const card of myCards) {
    myCardQuantities[card?.reference] = card?.inMyCollection || 0;
  }

  // Filtrer les cartes manquantes
  const missingCards = allCards.filter((card) => {
    const currentQuantity = myCardQuantities[card.reference] || 0;
    const matchesRarity = raritiesToInclude
      ? raritiesToInclude.includes(card.rarity)
      : true;

    // Déterminer la quantité désirée basée sur la rareté de la carte
    const desiredQuantity =
      card.rarity === 'RARE'
        ? rareQuantity
        : card.rarity === 'COMMON'
          ? commonQuantity
          : rareQuantity;

    return (
      currentQuantity < desiredQuantity &&
      card.rarity !== RARITY.UNIQUE &&
      card.type !== CARD_TYPE.TOKEN &&
      card.type !== CARD_TYPE.TOKEN_MANA &&
      matchesRarity
    );
  });

  // Calculer les quantités manquantes et retourner les cartes manquantes
  return missingCards.map((card) => {
    const desiredQuantity =
      card.rarity === 'RARE'
        ? rareQuantity
        : card.rarity === 'COMMON'
          ? commonQuantity
          : rareQuantity;

    return {
      ...card,
      inMyCollection: desiredQuantity - (myCardQuantities[card.reference] || 0),
    };
  });
};

export const getMissingDeckCards = (
  myCards: FullCard[],
  deckCards: FullCard[]
): FullCard[] => {
  // Créer un dictionnaire des quantités de cartes que nous possédons
  const myCardQuantities: Record<string, number> = {};

  for (const card of myCards) {
    myCardQuantities[card.reference] = card.inMyCollection || 0;
  }

  // Filtrer les cartes manquantes
  const missingCards = deckCards.filter((deckCard) => {
    const currentQuantity = myCardQuantities[deckCard.reference] || 0;
    return currentQuantity < (deckCard.inMyCollection || 0);
  });

  // Calculer les quantités manquantes et retourner les cartes manquantes
  return missingCards.map((deckCard) => {
    const currentQuantity = myCardQuantities[deckCard.reference] || 0;
    const missingQuantity = (deckCard.inMyCollection || 0) - currentQuantity;

    return {
      ...deckCard,
      inMyCollection: missingQuantity,
    };
  });
};

export const getExcessCards = (
  myCards: FullCard[],
  rareQuantity: number,
  commonQuantity: number,
  raritiesToInclude?: string[]
): FullCard[] => {
  // Filtrer les cartes excédentaires
  const excessCards = myCards.filter((card) => {
    const currentQuantity = card?.inMyCollection || 0;
    const matchesRarity = raritiesToInclude
      ? raritiesToInclude.includes(card.rarity)
      : true;

    // Déterminer la quantité désirée basée sur la rareté de la carte
    const desiredQuantity =
      card?.rarity === 'RARE'
        ? rareQuantity
        : card?.rarity === 'COMMON'
          ? commonQuantity
          : rareQuantity; // Utilisation de rareQuantity par défaut pour les autres raretés

    return (
      currentQuantity > desiredQuantity &&
      card.rarity !== RARITY.UNIQUE &&
      card.type !== CARD_TYPE.TOKEN &&
      card.type !== CARD_TYPE.TOKEN_MANA &&
      matchesRarity
    );
  });

  // Calculer les quantités excédentaires et retourner les cartes excédentaires
  return excessCards.map((card) => {
    const desiredQuantity =
      card.rarity === 'RARE'
        ? rareQuantity
        : card.rarity === 'COMMON'
          ? commonQuantity
          : rareQuantity; // Utilisation de rareQuantity par défaut pour les autres raretés

    return {
      ...card,
      inMyCollection: (card.inMyCollection || 0) - desiredQuantity,
    };
  });
};

export const compareCardCollections = (
  sharedCards: FullCard[],
  myCards: FullCard[]
): FullCard[] => {
  const result: FullCard[] = [];

  // On parcourt les cartes partagées (sharedCards)
  for (const sharedCard of sharedCards) {
    const matchingCard = myCards.find(
      (myCard) => myCard.reference === sharedCard.reference
    );

    if (
      matchingCard &&
      matchingCard.inMyCollection &&
      sharedCard.inMyCollection
    ) {
      // Calcul de la quantité minimum entre les deux collections
      const minQuantity = Math.min(
        matchingCard.inMyCollection,
        sharedCard.inMyCollection
      );

      // On ajoute la carte avec la quantité minimum
      result.push({
        ...sharedCard,
        inMyCollection: minQuantity,
      });
    }
  }

  return result;
};

// Fonction pour calculer les échanges possibles
export const calculateExchanges = (
  cards1: SimpleCard[],
  cards2: SimpleCard[],
  cardsNeeded: number
): Exchange[] => {
  const countCards = (cards: SimpleCard[]): Record<string, number> => {
    const cardCount: Record<string, number> = {};
    for (const card of cards) {
      cardCount[card.reference] =
        (cardCount[card.reference] || 0) + card.quantity;
    }
    return cardCount;
  };

  const cardCountA = countCards(cards1);
  const cardCountB = countCards(cards2);

  const cardExchanges: Exchange[] = [];

  for (const card in cardCountA) {
    if (
      cardCountA[card] > cardsNeeded &&
      (cardCountB[card] || 0) < cardsNeeded
    ) {
      const excessInA = cardCountA[card] - cardsNeeded;
      const neededInB = cardsNeeded - (cardCountB[card] || 0);
      const exchangeCount = Math.min(excessInA, neededInB);

      if (exchangeCount > 0) {
        cardExchanges.push({
          card,
          from: '1',
          to: '2',
          count: exchangeCount,
        });
      }
    }
  }

  for (const card in cardCountB) {
    if (
      cardCountB[card] > cardsNeeded &&
      (cardCountA[card] || 0) < cardsNeeded
    ) {
      const excessInB = cardCountB[card] - cardsNeeded;
      const neededInA = cardsNeeded - (cardCountA[card] || 0);
      const exchangeCount = Math.min(excessInB, neededInA);

      if (exchangeCount > 0) {
        cardExchanges.push({
          card,
          from: '2',
          to: '1',
          count: exchangeCount,
        });
      }
    }
  }

  return cardExchanges;
};

export const calculateTotalTextCards = (cards: string) => {
  return cards
    ?.split(';')
    ?.reduce(
      (sum, card) => sum + (Number.parseInt(card.split('ALT')[0]) || 0),
      0
    );
};

// Calculer un résumé des cartes
export const calculateSummary = (cards: FullCard[] | FullCard[]) => {
  const summary: SummaryProperties = {
    total: 0,
    heroes: 0,
    characters: 0,
    spells: 0,
    permanents: 0,
    common: 0,
    rare: 0,
    unique: 0,
    ax: 0,
    br: 0,
    mu: 0,
    ly: 0,
    or: 0,
    yz: 0,
    nbFaction: 0,
  };

  for (const card of cards) {
    if (!card) return;
    if (card.type === CARD_TYPE.HERO) summary.heroes += 1;
    if (card.type === CARD_TYPE.CHARACTER)
      summary.characters += card.quantity ?? 1;
    if (card.type === CARD_TYPE.SPELL) summary.spells += card.quantity ?? 1;
    if (
      card.type === CARD_TYPE.PERMANENT ||
      card.type === CARD_TYPE.EXPEDITION_PERMANENT ||
      card.type === CARD_TYPE.LANDMARK_PERMANENT
    )
      summary.permanents += card.quantity ?? 1;
    if (card.rarity === RARITY.COMMON) summary.common += card.quantity ?? 1;
    if (card.rarity === RARITY.RARE) summary.rare += card.quantity ?? 1;
    if (card.rarity === RARITY.UNIQUE) summary.unique += card.quantity ?? 1;

    if (card.mainFaction === FACTIONS.YZ) summary.yz += 1;
    if (card.mainFaction === FACTIONS.AX) summary.ax += 1;
    if (card.mainFaction === FACTIONS.BR) summary.br += 1;
    if (card.mainFaction === FACTIONS.MU) summary.mu += 1;
    if (card.mainFaction === FACTIONS.LY) summary.ly += 1;
    if (card.mainFaction === FACTIONS.OR) summary.or += 1;
    summary.total += card.quantity ?? 1;
  }
  summary.nbFaction =
    Number(summary.yz !== 0) +
    Number(summary.ax !== 0) +
    Number(summary.br !== 0) +
    Number(summary.mu !== 0) +
    Number(summary.ly !== 0) +
    Number(summary.or !== 0);

  return summary;
};

const parseMainCost = (mainCost: string | undefined | undefined): number => {
  if (!mainCost) return 0;
  const formattedCost = mainCost.replaceAll(/\D/g, '');
  return Number.parseInt(formattedCost, 10) || 0;
};

export const sortCards = (
  cards: FullCard[],
  primarySortMode: string,
  secondarySortMode: string,
  secondaryDirection: string,
  lang: string
): FullCard[] => {
  const factionOrder = ['AX', 'BR', 'LY', 'MU', 'OR', 'YZ', 'NE'];
  const rarityOrder = ['COMMON', 'RARE', 'UNIQUE'];
  const typeOrder = [
    'HERO',
    'CHARACTER',
    'SPELL',
    'PERMANENT',
    'TOKEN',
    'FOILER',
    'TOKEN_MANA',
  ];

  const compare = (
    a: FullCard,
    b: FullCard,
    mode: string,
    secondarySortMode: string
  ): number => {
    if (mode === PRIMARY_SORT_MODE.BY_TYPE) {
      if (secondarySortMode === SECONDARY_SORT_MODE.BY_NOTHING) {
        if (a.type === 'HERO' && b.type !== 'HERO') return -1;
        if (b.type === 'HERO' && a.type !== 'HERO') return 1;
        if (a.rarity !== b.rarity) {
          return rarityOrder.indexOf(a.rarity) - rarityOrder.indexOf(b.rarity);
        }
        if (a.type !== b.type) {
          return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
        }
        if (a.mainFaction !== b.mainFaction) {
          return (
            factionOrder.indexOf(a.mainFaction) -
            factionOrder.indexOf(b.mainFaction)
          );
        }
      } else {
        if (a.type !== b.type) {
          return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
        }
      }
    } else if (mode === PRIMARY_SORT_MODE.BY_FACTION) {
      if (secondarySortMode === SECONDARY_SORT_MODE.BY_NOTHING) {
        if (a.mainFaction !== b.mainFaction) {
          return (
            factionOrder.indexOf(a.mainFaction) -
            factionOrder.indexOf(b.mainFaction)
          );
        }
        if (a.rarity !== b.rarity) {
          return rarityOrder.indexOf(a.rarity) - rarityOrder.indexOf(b.rarity);
        }
        if (a.type !== b.type) {
          return typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
        }
      } else {
        if (a.mainFaction !== b.mainFaction) {
          return (
            factionOrder.indexOf(a.mainFaction) -
            factionOrder.indexOf(b.mainFaction)
          );
        }
      }
    }
    return 0;
  };

  const compareWithDirection = (
    a: FullCard,
    b: FullCard,
    mode: string,
    direction: string,
    lang: string
  ): number => {
    switch (mode) {
      // case SECONDARY_SORT_MODE.BY_LATEST_ADDITION: {
      //   const dateA = a.latestAddition
      //     ? new Date(a.latestAddition).getTime()
      //     : 0;
      //   const dateB = b.latestAddition
      //     ? new Date(b.latestAddition).getTime()
      //     : 0;
      //   return direction === SORT_DIRECTION.ASCENDING
      //     ? dateA - dateB
      //     : dateB - dateA;
      // }
      case SECONDARY_SORT_MODE.BY_MAIN_COST: {
        const costA = parseMainCost(a?.MAIN_COST);
        const costB = parseMainCost(b?.MAIN_COST);
        return direction === SORT_DIRECTION.ASCENDING
          ? costA - costB
          : costB - costA;
      }
      case SECONDARY_SORT_MODE.BY_NAME: {
        const nameA = a.name[lang] || '';
        const nameB = b.name[lang] || '';
        return direction === SORT_DIRECTION.ASCENDING
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      }
      case SECONDARY_SORT_MODE.BY_NUMBER: {
        const numberA = a.collectorNumberPrinted || '';
        const numberB = b.collectorNumberPrinted || '';

        const extractNumber = (number: string) => {
          const match = number.match(/\d+/);
          return match ? Number.parseInt(match[0], 10) : 0;
        };

        const numberA_ = extractNumber(numberA);
        const numberB_ = extractNumber(numberB);

        return direction === SORT_DIRECTION.ASCENDING
          ? numberA_ - numberB_
          : numberB_ - numberA_;
      }
      // No default
    }
    return 0;
  };

  return cards.sort((a, b) => {
    const primaryComparison = compare(a, b, primarySortMode, secondarySortMode);
    if (primaryComparison !== 0) return primaryComparison;
    return compareWithDirection(
      a,
      b,
      secondarySortMode,
      secondaryDirection,
      lang
    );
  });
};

export const getRandomCards = (
  cards: FullCard[],
  count: number,
  rarities?: string[],
  types?: string[],
  factions?: string[]
): FullCard[] => {
  // Types à exclure par défaut
  const excludedTypes = new Set(['TOKEN', 'FOILER', 'TOKEN_MANA']);

  let filteredCards = cards;

  if (rarities && rarities.length > 0) {
    filteredCards = filteredCards.filter((card) =>
      rarities.includes(card.rarity)
    );
  }

  filteredCards =
    types && types.length > 0
      ? filteredCards.filter((card) => types.includes(card.type))
      : filteredCards.filter((card) => !excludedTypes.has(card.type));

  if (factions && factions.length > 0) {
    filteredCards = filteredCards.filter((card) =>
      factions.includes(card.mainFaction)
    );
  }

  const shuffledCards = filteredCards.sort(() => 0.5 - Math.random());

  return shuffledCards.slice(0, count);
};

const removeAccents = (string_: string): string => {
  return string_?.normalize('NFD').replaceAll(/[\u0300-\u036F]/g, '');
};

export const filterCardsByQuery = (
  cards: FullCard[],
  query: string,
  currentLanguage: string
): FullCard[] => {
  const nameTerms: string[] = [];
  const effectTerms: string[] = [];

  const regex = /"([^"]+)"|(\S+)/g;
  let match;

  while ((match = regex.exec(query)) !== null) {
    if (match[1]) {
      effectTerms.push(match[1].toLowerCase());
    } else if (match[2]) {
      nameTerms.push(match[2].toLowerCase());
    }
  }

  return cards.filter((card) => {
    const cardName = removeAccents(card.name[currentLanguage]?.toLowerCase());
    const mainEffect = card?.MAIN_EFFECT
      ? removeAccents(card.MAIN_EFFECT[currentLanguage]?.toLowerCase())
      : '';

    const matchesName = nameTerms.every((term) =>
      cardName?.includes(removeAccents(term))
    );

    const matchesEffect = effectTerms.every((term) =>
      mainEffect?.includes(removeAccents(term))
    );

    return matchesName && matchesEffect;
  });
};

const getBaseReference = (reference: string) =>
  reference.replace(/(COREKS_|CORE_)/, 'CORE_');

export const fusionSets = (
  myCards: FullCard[],
  allCards: FullCard[]
): FullCard[] => {
  const mergedCardsMap: { [baseRef: string]: FullCard } = {};

  // Filtrer les cartes selon leur set
  const ksCards = myCards.filter((card) => card.reference.includes('COREKS'));
  const beyondTheGatesCards = myCards.filter((card) =>
    card.reference.includes('CORE_')
  );

  const otherCards = myCards.filter(
    (card) =>
      !card.reference.includes('COREKS') && !card.reference.includes('CORE_')
  );

  // Fonction pour trouver l'équivalent non-KS d'une carte KS
  const findNonKSSet = (baseReference: string): FullCard | undefined => {
    return allCards.find(
      (card) => card.reference === baseReference.replace('COREKS', 'CORE')
    );
  };

  // Ajouter les cartes BTG au map
  for (const card of beyondTheGatesCards) {
    const baseReference = getBaseReference(card.reference);
    mergedCardsMap[baseReference] = { ...card };
  }

  // Fusionner KS avec BTG si possible
  for (const card of ksCards) {
    const baseReference = getBaseReference(card.reference);

    if (mergedCardsMap[baseReference]) {
      // Fusionner les quantités si la carte existe déjà en BTG
      if (card.rarity !== 'UNIQUE') {
        mergedCardsMap[baseReference].inMyCollection =
          (mergedCardsMap[baseReference].inMyCollection ?? 0) +
          (card.inMyCollection ?? 0);
      }
    } else {
      const nonKSCard = findNonKSSet(card.reference);

      mergedCardsMap[baseReference] = nonKSCard
        ? {
            ...nonKSCard,
            inMyCollection:
              (nonKSCard.inMyCollection ?? 0) + (card.inMyCollection ?? 0),
          }
        : { ...card };
    }
  }

  // Retourner toutes les cartes fusionnées + les cartes des autres sets
  return [...Object.values(mergedCardsMap), ...otherCards];
};

export const filterBySet = (cards: FullCard[], set: string) => {
  switch (set) {
    case SET.KS: {
      return cards.filter((card) => card.reference.includes('COREKS'));
    }
    case SET.BEYOND_THE_GATES: {
      return cards.filter((card) => card.reference.includes('CORE_'));
    }
    case SET.TRIAL_BY_FROST: {
      return cards.filter((card) => card?.reference.includes('ALIZE_'));
    }
    // No default
  }

  return cards;
};

export const particleIdToName = (
  cards: FullCard[],
  particleId: string,
  currentLanguage: string
) => {
  const card = cards.find((card) => card.reference.includes(particleId));
  if (card && card.name && card.name[currentLanguage]) {
    return card.name[currentLanguage];
  }
  return 'Unknown Card';
};

export const filterCardsByParticleAndFaction = (
  allCards: FullCard[],
  particleId: string,
  mainFaction?: string
): FullCard[] => {
  return allCards.filter((card) => {
    const referenceMatch = card.reference.match(PARTICULE_ID_REGEX);
    const cardParticleId = referenceMatch ? referenceMatch[1] : undefined;
    return mainFaction
      ? cardParticleId === particleId && card.mainFaction === mainFaction
      : cardParticleId === particleId;
  });
};

export const getCardByReference = (reference: string, cards: FullCard[]) => {
  return cards.find((card) => card.reference === reference);
};

const exceptionReferences = new Set([
  'ALT_COREKS_B_YZ_17',
  'ALT_COREKS_B_MU_09',
  'ALT_COREKS_B_AX_18',
  'ALT_COREKS_B_BR_22',
  'ALT_COREKS_B_AX_07',
  'ALT_COREKS_B_BR_07',
  'ALT_COREKS_B_MU_08',
  'ALT_COREKS_B_LY_09',
  'ALT_COREKS_B_OR_20',
  'ALT_COREKS_B_OR_21',
  'ALT_COREKS_B_YZ_11',
  'ALT_COREKS_B_LY_21',
]);

export const getCompleteImagePath = (
  card: FullCard,
  currentLanguage: string
): string => {
  const lang =
    currentLanguage === 'en'
      ? 'en_US'
      : `${currentLanguage}_${currentLanguage.toUpperCase()}`;
  const isUnique = card.rarity === RARITY.UNIQUE;

  const smallReference =
    card.reference.match(/^((?:[^_]+_){4}[^_]+)/)?.[0] || card.reference;
  const isException = exceptionReferences.has(smallReference);
  const coreSegment = smallReference.includes('COREKS')
    ? 'COREKS'
    : smallReference.includes('ALIZE')
      ? 'ALIZE'
      : 'CORE';

  const modifiedReference = isException
    ? smallReference
    : smallReference.replace('_COREKS_', '_CORE_');

  const imageExtension =
    smallReference.includes('ALIZE') && !isUnique ? 'png' : 'jpg';

  const url = `https://altered-prod-eu.s3.amazonaws.com/Art/${coreSegment}/CARDS/${modifiedReference}${isUnique ? '/UNIQUE/JPG/' : '/JPG/'}${lang}/${card.imagePath[currentLanguage]}.${imageExtension}`;

  return url;
};
// https://altered-prod-eu.s3.amazonaws.com/Art/COREKS/CARDS/ALT_CORE_B_BR_01/JPG/fr_FR/a31f33497ab968ddd60e9257b1cbf82c.jpg.jpg
export const getAssetUrls = (reference: string, rarity: string): string => {
  const modifiedReference = reference.replace('_COREKS_', '_CORE_');

  let rarityLetter = '';
  switch (rarity) {
    case RARITY.COMMON: {
      rarityLetter = 'C';
      break;
    }
    case RARITY.RARE: {
      rarityLetter = 'R';
      break;
    }
    case RARITY.UNIQUE: {
      {
        rarityLetter = 'U';
      }
      break;
    }
  }

  const baseUrl = `https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/${modifiedReference}`;
  return `${baseUrl}/${modifiedReference}_${rarityLetter}_WEB.jpg`;
};

export const getUniqueFromList = (
  cards: FullCard[],
  member?: Member
): FullCard[] => {
  const uniqueCards = cards
    ?.filter((card) => isUnique(card.reference))
    .map((card) => ({
      ...card,
      owner: member ? member.pseudo.toString() : undefined,
    }));
  return uniqueCards;
};
