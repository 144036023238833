import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useCollection } from './api/useCollection';
import useAltCards from './useAltCards';
import useLoadCardsFromJson from './useLoadCardsFromJson';
import { CARDS_PATH } from '../constants/constants';
import { setCollectionCards } from '../store/slices/collectionCardsSlice';
import { RootState } from '../store/store';
import { FullCard } from '../types/altCard';
import { fusionSets } from '../utils/cardUtilities';
import { createTextFromCollection } from '../utils/formatCards';

const useMyCards = (isFusion?: boolean) => {
  const dispatch = useDispatch();
  const { getCollection, updateCollection, error } = useCollection();
  const [fusionCards, setFusionCards] = useState<FullCard[]>([]);
  const { loadingLabel, loading, setLoading, recreateCardsFromText } =
    useAltCards('loading.loadingCollection');

  const user = useSelector((state: RootState) => state.user);

  const myCards = useSelector(
    (state: RootState) => state.collectionCards.cards
  );

  const isAllFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );

  const isFusionEnabled = isFusion ?? isAllFusionEnabled;

  const { allCards } = useLoadCardsFromJson(CARDS_PATH, isFusionEnabled);

  const updateRemoteCollection = async (
    collection: FullCard[]
  ): Promise<FullCard[] | undefined> => {
    if (!collection || collection.length <= 0) return;
    const collectionText = createTextFromCollection(collection);
    updateCollection(collectionText, user);
    return;
  };

  const recreateCollectionFromText = async (
    text: string
  ): Promise<FullCard[] | undefined> => {
    if (!allCards) return;

    setLoading(true);
    const myCollection = await recreateCardsFromText({ text });
    setLoading(false);

    if (myCollection) {
      dispatch(setCollectionCards(myCollection));
      return myCollection;
    }

    return;
  };

  useEffect(() => {
    if (allCards && myCards.length > 0 && isFusionEnabled) {
      setFusionCards(fusionSets(myCards, allCards));
    } else {
      setFusionCards(myCards);
    }
  }, [allCards, isFusionEnabled, myCards]);

  useEffect(() => {
    const fetchCollection = async () => {
      setLoading(true);
      const result = await getCollection(user);

      if (result) {
        await recreateCollectionFromText(result);
      }
      setLoading(false);
    };

    if (user?.accessToken && allCards && myCards.length <= 0) fetchCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, allCards]);

  return {
    fusionCards,
    error,
    loadingLabel,
    loading,
    updateRemoteCollection,
    recreateCollectionFromText,
  };
};

export default useMyCards;
