import React from 'react';

import ModalTemplate from '../../components/ModalTemplate';
import { TeamDetail } from '../../types/team';

interface MemberSelectionModalProperties {
  isOpen: boolean;
  closeModal: () => void;
  team: TeamDetail;
  visibleMembers: number[];
  setVisibleMembers: (ids: number[]) => void;
}

const MemberSelectionModal: React.FC<MemberSelectionModalProperties> = ({
  isOpen,
  closeModal,
  team,
  visibleMembers,
  setVisibleMembers,
}) => {
  const toggleMember = (idUser: number) => {
    const newVisibleMembers = visibleMembers.includes(idUser)
      ? visibleMembers.filter((id) => id !== idUser)
      : [...visibleMembers, idUser];
    setVisibleMembers(newVisibleMembers);
  };

  return (
    <ModalTemplate isOpen={isOpen} closeModal={closeModal}>
      {team.members.map((member) => (
        <div key={member.idUser} className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={visibleMembers.includes(member.idUser)}
            onChange={() => toggleMember(member.idUser)}
          />
          <span>{member.pseudo}</span>
        </div>
      ))}
    </ModalTemplate>
  );
};

export default MemberSelectionModal;
