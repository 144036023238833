import { useState } from 'react';

import api from '../../api/axiosInstance';
import { BasicCard, UniqueCard_DDB } from '../../types/altCard';
import { handleError } from '../../utils/errorHandler';

export interface FetchUniqueCardsResult {
  incompleteUniqueCards: UniqueCard_DDB[];
  failedUniques: BasicCard[];
}
export const useUniqueCards = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getUniqueCards = async (
    uniqueCards: BasicCard[]
  ): Promise<FetchUniqueCardsResult> => {
    setIsLoading(true);
    setError(undefined);

    const incompleteUniqueCards: UniqueCard_DDB[] = [];
    const failedUniques: BasicCard[] = [];

    try {
      // Extraire les références des cartes à récupérer
      const references = uniqueCards.map((card) => card.reference);

      // Effectuer un seul appel API avec toutes les références
      const response = await api.post<UniqueCard_DDB[]>('/uniques/bulk', {
        references,
      });

      if (response.status === 200 && response.data) {
        incompleteUniqueCards.push(...response.data);

        // Comparer les références pour identifier les échecs
        const receivedReferences = new Set(
          response.data.map((card) => card.reference)
        );

        for (const card of uniqueCards) {
          if (!receivedReferences.has(card.reference)) {
            failedUniques.push(card); // Cette carte n'a pas été reçue dans la réponse
          }
        }
      } else {
        failedUniques.push(...uniqueCards);
      }
    } catch (error) {
      handleError(error, setError);
      failedUniques.push(...uniqueCards);
    } finally {
      setIsLoading(false);
    }

    return { incompleteUniqueCards, failedUniques };
  };

  const updateUniqueCards = async (
    uniqueCard: UniqueCard_DDB,
    onProgressUpdate?: (message: string) => void
  ): Promise<void> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.put<UniqueCard_DDB>(
        `/uniques/${uniqueCard.reference}`,
        uniqueCard
      );

      if (response.status === 200) {
        onProgressUpdate && onProgressUpdate(`Uploaded 1/1 cards successfully`);
      } else {
        console.error(`Failed to upload card: ${uniqueCard.reference}`);
      }
    } catch (error_) {
      handleError(error_, setError);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadUniqueCards = async (
    uniqueCards: UniqueCard_DDB[],
    onProgressUpdate?: (message: string) => void
  ): Promise<void> => {
    setIsLoading(true);
    setError(undefined);

    try {
      await Promise.all(
        uniqueCards.map(async (card, index) => {
          try {
            const response = await api.post('/uniques/create', card);

            if (response.status === 201) {
              onProgressUpdate &&
                onProgressUpdate(
                  `Uploaded ${index + 1}/${uniqueCards.length} cards successfully`
                );
            } else {
              console.error(`Failed to upload card: ${card.reference}`);
            }
          } catch (error_) {
            console.error(`Error uploading card ${card.reference}:`, error_);
          }
        })
      );
    } catch (error_) {
      handleError(error_, setError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    getUniqueCards,
    uploadUniqueCards,
    updateUniqueCards,
    isLoading,
    error,
  };
};
