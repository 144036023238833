import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../components/Button';
import { CARDS_PATH } from '../constants/constants';
import { UNIQUE_TABS } from '../constants/tabs';
import { useTeam } from '../hooks/api/useTeam';
import useAltCards from '../hooks/useAltCards';
import useLoadCardsFromJson from '../hooks/useLoadCardsFromJson';
import BackButton from '../parts/BackButton';
import CardList from '../parts/CardList';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import MemberSelectionModal from '../parts/modals/MemberSelectionModal';
import MembersModal from '../parts/modals/MembersModal';
import Tabs, { Tab } from '../parts/Tabs';
import { RootState } from '../store/store';
import { FullCard } from '../types/altCard';
import { Member, TeamDetail } from '../types/team';
import { fusionSets } from '../utils/cardUtilities';
import { getNavigateUrl } from '../utils/getNavigateUrl';
import { getTeamUniqueCompleteCards } from '../utils/teamUtilities';

const TeamDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { idTeam } = useParams<{ idTeam: string }>();

  const { recreateCardsFromText } = useAltCards('loading.loading');
  const { leaveTeam, deleteTeam, getTeamDetails, isLoading, error } = useTeam();

  const [teamDetails, setTeamDetails] = useState<TeamDetail>();
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [mainSelectedTab, setMainSelectedTab] = useState<string>('teamMembers');
  const [selectedCards, setSelectedCards] = useState<FullCard[]>();
  const [isMembersModalOpen, setIsMembersModalOpen] = useState<boolean>(false);
  const [isMemberSelectionOpen, setIsMemberSelectionOpen] =
    useState<boolean>(false);
  const [visibleMembers, setVisibleMembers] = useState<number[]>([]);

  const user = useSelector((state: RootState) => state.user);
  const isFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );

  const { allCards } = useLoadCardsFromJson(CARDS_PATH, isFusionEnabled);

  const fetchMemberCards = async (
    selectedMember: Member
  ): Promise<FullCard[] | undefined> => {
    if (
      !allCards ||
      !teamDetails ||
      (selectedMember.completeCards && selectedMember.completeCards.length > 0)
    ) {
      return;
    }

    const recreatedCards = await recreateCardsFromText({
      text: selectedMember.cards || '',
    });

    const fusedCards =
      isFusionEnabled && recreatedCards
        ? fusionSets(recreatedCards, allCards)
        : recreatedCards;
    return fusedCards;
  };

  const fetchSelectedMemberCards = async (selectedMember: Member) => {
    if (!teamDetails || teamDetails.members.length === 0) {
      return;
    }

    const updatedMembers = [...teamDetails.members];

    const fusedCards = await fetchMemberCards(selectedMember);
    if (fusedCards) {
      // Trouver l'index du membre et mettre à jour localement
      const selectedMemberIndex = updatedMembers.findIndex(
        (m) => m.idUser === selectedMember.idUser
      );

      if (selectedMemberIndex !== -1) {
        updatedMembers[selectedMemberIndex] = {
          ...selectedMember,
          completeCards: fusedCards,
        };
      }
      // Une seule mise à jour globale de teamDetails
      setTeamDetails({
        ...teamDetails,
        members: updatedMembers,
      });

      setSelectedCards(fusedCards);
    }
  };

  const fetchAllTeamMembersCards = async () => {
    if (!teamDetails || teamDetails.members.length === 0) {
      return;
    }

    const updatedMembers = [...teamDetails.members];

    for (const member of teamDetails.members) {
      const fusedCards = await fetchMemberCards(member);
      if (fusedCards) {
        const selectedMemberIndex = updatedMembers.findIndex(
          (m) => m.idUser === member.idUser
        );

        if (selectedMemberIndex !== -1) {
          updatedMembers[selectedMemberIndex] = {
            ...member,
            completeCards: fusedCards,
          };
        }
      }
    }

    setTeamDetails({
      ...teamDetails,
      members: updatedMembers,
    });

    setSelectedCards(
      getTeamUniqueCompleteCards({ ...teamDetails, members: updatedMembers })
    );
  };

  useEffect(() => {
    const fetchTeamDetails = async (idTeam: string) => {
      const teamAPI = await getTeamDetails({ idTeam, user });

      if (teamAPI) {
        setTeamDetails(teamAPI);
        if (teamAPI.members.length > 0) {
          setVisibleMembers([teamAPI.createdBy as number]);
          setSelectedTab(teamAPI.createdBy.toString());
        }
      }
    };

    if (idTeam && !teamDetails) {
      fetchTeamDetails(idTeam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (teamDetails) {
      setSelectedCards(undefined);
      if (mainSelectedTab === 'teamUniques') {
        fetchAllTeamMembersCards();
      } else {
        const selectedMemberIndex = teamDetails.members.findIndex(
          (m) => m.idUser === visibleMembers[0]
        );
        setSelectedTab(visibleMembers[0].toString());
        setSelectedCards(
          teamDetails.members[selectedMemberIndex]?.completeCards
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainSelectedTab]);

  useEffect(() => {
    if (teamDetails) {
      const member = teamDetails.members.find(
        (m) => m.idUser.toString() === selectedTab
      );
      if (member && !member.completeCards) {
        fetchSelectedMemberCards(member);
      } else {
        setSelectedCards(member?.completeCards);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const tabs: { [key: string]: Tab } = {};

  if (teamDetails) {
    for (const member of teamDetails.members) {
      if (visibleMembers.includes(member.idUser)) {
        tabs[member.idUser] = { key: `${member.idUser}`, label: member.pseudo };
      }
    }
  } else {
    return <Loading label={t('loading.loading')} />;
  }

  document.title = t('navigation.teams') + ` - ${teamDetails?.name} | Altverse`;

  const goBackToTeams = () => {
    navigate(getNavigateUrl(i18n.language, 'teams'));
  };

  const handleLeave = async () => {
    if (!teamDetails) return;
    const response = await leaveTeam({ idTeam: teamDetails.idTeam, user });
    if (response) goBackToTeams();
  };

  const handleDelete = async () => {
    if (!teamDetails) return;
    const response = await deleteTeam({ idTeam: teamDetails.idTeam, user });
    if (response) goBackToTeams();
  };

  if (isLoading || !teamDetails) {
    return <Loading label={t('loading.loading')} />;
  }

  if (!user?.idUser) {
    goBackToTeams();
  }

  return (
    <Layout>
      <div className="container mx-auto p-4">
        {error && <p className="text-red-500">{error}</p>}
        {teamDetails && (
          <div className="space-y-16">
            <div className="space-y-4">
              <BackButton to="teams" title={teamDetails.name} />
              <div className="flex w-full h-fit space-x-2">
                <Button full onClick={() => setIsMembersModalOpen(true)}>
                  {t('actions.members')}
                </Button>

                <Button full onClick={handleLeave}>
                  {t('actions.leaveTeam')}
                </Button>
                {user.idUser === teamDetails.createdBy && (
                  <Button full onClick={handleDelete}>
                    {t('actions.removeTeam')}
                  </Button>
                )}
              </div>
            </div>
            <div className="space-y-2">
              <Tabs
                selectedTab={mainSelectedTab}
                setSelectedTab={setMainSelectedTab}
                tabs={UNIQUE_TABS}
                full
              />
              {mainSelectedTab === 'teamMembers' && (
                <Tabs
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  tabs={tabs}
                  handleAction={() => setIsMemberSelectionOpen(true)}
                  full
                />
              )}
            </div>
            {selectedCards ? (
              <CardList cards={selectedCards} showCollectionFilter isExport />
            ) : (
              <span className="flex justify-center items-center m-6 font-chillaxBold text-lg">
                {t('loading.loadingCards')}
              </span>
            )}
          </div>
        )}
      </div>
      <MembersModal
        isOpen={isMembersModalOpen}
        closeModal={() => setIsMembersModalOpen(false)}
        team={teamDetails}
      />
      <MemberSelectionModal
        isOpen={isMemberSelectionOpen}
        closeModal={() => setIsMemberSelectionOpen(false)}
        team={teamDetails}
        visibleMembers={visibleMembers}
        setVisibleMembers={setVisibleMembers}
      />
    </Layout>
  );
};

export default TeamDetailPage;
