import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from './Button';
import DynamicSortButtons from './DynamicSortButtons';
import Filter from './Filter';
import ModalTemplate from './ModalTemplate';
import Separator from './Separator';
import {
  PRIMARY_SORT_MODE,
  SECONDARY_SORT_MODE,
  SET,
  SORT_DIRECTION,
} from '../constants/constants';
import {
  cardsRarityData,
  cardsTypeData,
  cardsTypeDeckData,
  collectionFilterModes,
  factionData,
} from '../data/filtersData';

interface FilterCardsModalProperties {
  modalIsOpen: boolean;
  closeModal: () => void;
  showCollectionFilter: boolean;
  setCardTypes: (array: string[]) => void;
  setCardRarities: (array: string[]) => void;
  setFactionFilters: (array: string[]) => void;
  setCollectionFilterValue: (number: number) => void;
  setCollectionFilterMode: (source: string) => void;
  factionFilters: string[];
  cardTypes: string[];
  cardRarities: string[];
  collectionFilterMode: string;
  collectionFilterValue: number;
  primarySortMode: string;
  setPrimarySortMode: (mode: string) => void;
  set: string;
  faction?: string;
  setSet: (set: string) => void;
  secondarySortMode: string;
  setSecondarySortMode: (mode: string) => void;
  secondarySortDirection: string;
  setSecondarySortDirection: (direction: string) => void;
}

const FilterCardsModal: React.FC<FilterCardsModalProperties> = ({
  modalIsOpen,
  closeModal,
  showCollectionFilter,
  setCardTypes,
  setCardRarities,
  setFactionFilters,
  setCollectionFilterValue,
  setCollectionFilterMode,
  factionFilters,
  cardTypes,
  cardRarities,
  collectionFilterMode,
  collectionFilterValue,
  primarySortMode,
  setPrimarySortMode,
  set,
  faction,
  setSet,
  secondarySortMode,
  setSecondarySortMode,
  secondarySortDirection,
  setSecondarySortDirection,
}) => {
  const { t } = useTranslation();

  const resetFilters = () => {
    setFactionFilters([]);
    setCardTypes([]);
    setCardRarities([]);
    setCollectionFilterMode('');
    setCollectionFilterValue(0);
  };

  const toggleSecondarySortDirection = (direction?: string) => {
    setSecondarySortDirection(
      direction ||
        (secondarySortDirection === SORT_DIRECTION.ASCENDING
          ? SORT_DIRECTION.DESCENDING
          : SORT_DIRECTION.ASCENDING)
    );
  };

  const setOptions = [
    {
      mode: SET.ALL,
      label: 'filterTitle.all',
    },
    { mode: SET.KS, label: 'filterTitle.ks' },
    { mode: SET.BEYOND_THE_GATES, label: 'set.beyondTheGates' },
    { mode: SET.TRIAL_BY_FROST, label: 'set.trialByFrost' },
  ];

  const setCollectionOptions = [
    {
      mode: SET.ALL,
      label: 'filterTitle.all',
    },
    { mode: SET.KS, label: 'filterTitle.ks' },
    { mode: SET.BEYOND_THE_GATES, label: 'set.beyondTheGates' },
    { mode: SET.TRIAL_BY_FROST, label: 'set.trialByFrost' },
  ];

  const primarySortOptions = [
    {
      mode: PRIMARY_SORT_MODE.BY_NOTHING,
      label: 'filterTitle.nothing',
    },
    { mode: PRIMARY_SORT_MODE.BY_TYPE, label: 'filterTitle.type' },
    { mode: PRIMARY_SORT_MODE.BY_FACTION, label: 'filterTitle.faction' },
  ];

  const secondarySortOptions = [
    {
      mode: SECONDARY_SORT_MODE.BY_NOTHING,
      label: 'filterTitle.nothing',
    },
    {
      mode: SECONDARY_SORT_MODE.BY_LATEST_ADDITION,
      label: 'filterTitle.latestAddition',
      showIcon: true,
      showCollection: true,
    },
    {
      mode: SECONDARY_SORT_MODE.BY_MAIN_COST,
      label: 'filterTitle.mana',
      showIcon: true,
    },
    {
      mode: SECONDARY_SORT_MODE.BY_NAME,
      label: 'filterTitle.name',
      showIcon: true,
    },
    {
      mode: SECONDARY_SORT_MODE.BY_NUMBER,
      label: 'filterTitle.number',
      showIcon: true,
    },
  ];

  return (
    <ModalTemplate
      isOpen={modalIsOpen}
      closeModal={closeModal}
      maxWidth="max-w-2xl"
      width="w-full"
    >
      <div className={'space-y-4'}>
        <div className={'grid grid-cols-3 gap-2 md:gap-4'}>
          {!faction && (
            <Filter
              id="factionFilter"
              label={t('filterTitle.faction')}
              value={factionFilters}
              onChange={(value) => setFactionFilters(value as string[])}
              options={factionData}
              multiSelect
            />
          )}
          <Filter
            id="FullCard"
            label={t('filterTitle.type')}
            value={cardTypes}
            onChange={(value) => setCardTypes(value as string[])}
            options={faction ? cardsTypeDeckData : cardsTypeData}
            multiSelect
          />
          <Filter
            id="cardRarity"
            label={t('filterTitle.rarity')}
            value={cardRarities}
            onChange={(value) => setCardRarities(value as string[])}
            options={cardsRarityData}
            multiSelect
          />
          {showCollectionFilter && (
            <div className="flex flex-col">
              <label className="block text-sm font-chillaxBold text-darkBlue mb-2">
                {t('filterTitle.inMyCollection')}
              </label>
              <div className="flex space-x-2">
                <div className="relative">
                  <select
                    value={collectionFilterMode}
                    onChange={(error) =>
                      setCollectionFilterMode(error.target.value)
                    }
                    className="block appearance-none w-16 bg-lightBlue border-2 border-darkBlue px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                  >
                    {collectionFilterModes.map((mode) => (
                      <option key={mode.value} value={mode.value}>
                        {mode.label}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-darkBlue">
                    <svg
                      className="fill-current h-4 w-fit"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M7 10l5 5 5-5H7z" />
                    </svg>
                  </div>
                </div>
                <input
                  type="number"
                  min={0}
                  value={collectionFilterValue}
                  onChange={(error) =>
                    setCollectionFilterValue(Number(error.target.value))
                  }
                  className="h-full p-2 w-16 block shadow text-xs md:text-sm rounded border-2 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                />
              </div>
            </div>
          )}
        </div>
        <Separator />
        <label className="block text-sm font-chillaxBold text-gray-700">
          {t('filterTitle.sortMode')}
        </label>
        <DynamicSortButtons
          sortMode={set}
          setSortMode={setSet}
          options={showCollectionFilter ? setCollectionOptions : setOptions}
        />
        <DynamicSortButtons
          sortMode={primarySortMode}
          setSortMode={setPrimarySortMode}
          options={primarySortOptions}
        />
        <DynamicSortButtons
          sortMode={secondarySortMode}
          sortDirection={secondarySortDirection}
          setSortMode={setSecondarySortMode}
          toggleSortDirection={toggleSecondarySortDirection}
          options={secondarySortOptions}
          showCollectionFilter={showCollectionFilter}
        />
        <Separator />
        <Button onClick={closeModal} full>
          {t('actions.validate')}
        </Button>
        <Button onClick={resetFilters} full>
          {t('actions.reset')}
        </Button>
      </div>
    </ModalTemplate>
  );
};

export default FilterCardsModal;
