import { useEffect, useState } from 'react';

import { HiOutlineDotsHorizontal, HiOutlineDotsVertical } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';

import SidePanelCollectionContent from './SidePanelCollectionContent';
import { toggleSidePanel } from '../store/slices/sidePanelSlice';
import { RootState } from '../store/store';

interface SidePanelOptionsProperties {
  handleShareCollection?: () => void;
}

const SidePanelOptions = ({
  handleShareCollection,
}: SidePanelOptionsProperties) => {
  const dispatch = useDispatch();

  const isSidePanelOpen = useSelector(
    (state: RootState) => state.sidePanel.isSidePanelOpen
  );

  const [isContentVisible, setIsContentVisible] = useState(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isSidePanelOpen) {
      timeout = setTimeout(() => setIsContentVisible(true), 150);
    } else {
      setIsContentVisible(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isSidePanelOpen]);

  return (
    <div
      className={`fixed left-0 transition-all duration-300 z-40 pt-16 ${
        isSidePanelOpen ? 'w-full md:w-1/5' : 'w-0'
      } shadow-xl overflow-hidden border-r-4 border-darkBlue bg-beige`}
      style={{ height: '100vh' }}
    >
      {isSidePanelOpen ? (
        isContentVisible && (
          <SidePanelCollectionContent
            handleShareCollection={handleShareCollection}
          />
        )
      ) : (
        <>
          <button
            onClick={() => dispatch(toggleSidePanel())}
            className="md:hidden group fixed w-1/3 flex justify-center right-1/2 translate-x-1/2 bottom-0 p-3 py-0.5 z-50 bg-darkBlue text-white text-xl rounded-t-md"
          >
            <HiOutlineDotsHorizontal />
          </button>

          <button
            onClick={() => dispatch(toggleSidePanel())}
            className="hidden md:block group fixed top-1/2 h-1/4 -left-3 transform -translate-y-1/2 py-3 p-1 z-50 bg-darkBlue text-white text-xl rounded-r-md transition-all duration-300 hover:left-0"
          >
            <HiOutlineDotsVertical className="group-hover:scale-110 transition-transform duration-300" />
          </button>
        </>
      )}
    </div>
  );
};

export default SidePanelOptions;
