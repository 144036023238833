export const extractSmallReference = (reference: string): string => {
  return reference.split('_').slice(0, 5).join('_');
};

export const extractNumber = (input: string): number | undefined => {
  const match = input.match(/_(\d+)$/);
  return match ? Number.parseInt(match[1], 10) : undefined;
};

export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = undefined;
};
