import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';

import { SUPPORTED_LANGUAGE } from './constants/constants';
import AltCards from './pages/AltCards';
import CardExchange from './pages/CardExchange';
import CollectionSecret from './pages/CollectionSecret';
import DeckBuilder from './pages/DeckBuilder';
import Decks from './pages/Decks';
import DeckChecker from './pages/DecksChecker';
import DonationHelper from './pages/DonationHelper';
import Friends from './pages/Friends';
import Home from './pages/Home';
import Market from './pages/Market';
import Profil from './pages/Profil';
import Sealed from './pages/Sealed';
import SharedCollection from './pages/SharedCollection';
import Support from './pages/Support';
import TeamDetailPage from './pages/TeamDetailPage';
import TeamsPage from './pages/TeamsPage';
import Tools from './pages/Tools';
import UniqueMarket from './pages/UniqueMarket';
import NavBar from './parts/NavBar';
import NavBarMobile from './parts/NavBarMobile';
import ScrollToTopButton from './parts/ScrollToTopButton';

const App: React.FC = () => {
  const { i18n } = useTranslation();

  const LanguageHandler: React.FC<{ component: React.FC }> = ({
    component: Component,
  }) => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lang = pathSegments[1];

    if (!SUPPORTED_LANGUAGE.includes(lang)) {
      return (
        <Navigate
          to={`/${i18n.language}${location.pathname}${location.search}`}
          replace
        />
      );
    }

    return <Component />;
  };

  const routes = [
    { path: '/:lang', component: Home },
    { path: '/:lang/*', component: Home },
    { path: '/:lang/support', component: Support },

    { path: '/:lang/cards', component: AltCards },

    { path: '/:lang/profil', component: Profil },

    { path: '/:lang/teams', component: TeamsPage },
    { path: '/:lang/team/:idTeam', component: TeamDetailPage },

    { path: '/:lang/decks', component: Decks },
    { path: '/:lang/decks/:idDeck', component: DeckBuilder },
    { path: '/:lang/decks/checker', component: DeckChecker },

    { path: '/:lang/tools', component: Tools },
    { path: '/:lang/tools/sealed', component: Sealed },

    { path: '/:lang/market', component: Market },
    { path: '/:lang/market/friends', component: Friends },
    { path: '/:lang/market/uniques', component: UniqueMarket },
    { path: '/:lang/market/exchange', component: CardExchange },
    { path: '/:lang/market/donation', component: DonationHelper },

    { path: '/:lang/coco', component: CollectionSecret },
    { path: '/:lang/shared/:sharedCode', component: SharedCollection },
  ];

  return (
    <Router>
      <NavBar />
      <NavBarMobile />
      <ScrollToTopButton />
      <Routes>
        <Route
          path="/"
          element={<Navigate to={`/${i18n.language}`} replace />}
        />
        {routes.map(({ path, component }) => (
          <Route
            key={path}
            path={path}
            element={<LanguageHandler component={component} />}
          />
        ))}
        <Route
          path="*"
          element={<Navigate to={`/${i18n.language}`} replace />}
        />
      </Routes>
    </Router>
  );
};

export default App;
