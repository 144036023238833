import React from 'react';

interface LoadingProperties {
  label: string;
  isFull?: boolean;
}

const Loading: React.FC<LoadingProperties> = ({ label, isFull }) => {
  return (
    <div
      className={`flex w-full ${isFull ? 'h-full' : 'h-screen'} justify-center items-center`}
    >
      <p className="text-3xl font-chillaxBold uppercase text-center">{label}</p>
    </div>
  );
};

export default Loading;
