import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import ModalTemplate from '../../components/ModalTemplate';
import Separator from '../../components/Separator';
import SmallSeparator from '../../components/SmallSeparator';
import {
  EXPORT_CARD_FORMAT,
  EXPORT_FORMAT,
  MAX_FACTIONS,
  MAX_HEROES,
  MIN_CARDS,
} from '../../constants/constants';
import useRefetchCards from '../../hooks/useRefetchCards';
import useSealedValidation from '../../hooks/useSealedValidation';
import { FullCard } from '../../types/altCard';
import { ExportOption } from '../../types/export';
import { getUniqueFromList } from '../../utils/cardUtilities';
import { ExportHandler } from '../../utils/exportUtilities';
import Loading from '../Loading';

interface ExportModalProperties {
  isOpen: boolean;
  closeModal: () => void;
  cards: FullCard[];
  exportOptions: ExportOption[];
  exportToolsOptions: ExportOption[];
  isSealed?: boolean;
}

const ExportModal: React.FC<ExportModalProperties> = ({
  isOpen,
  closeModal,
  cards,
  exportOptions,
  exportToolsOptions,
  isSealed,
}) => {
  const { t } = useTranslation();
  const { refetchCards, loading, loadingLabel } = useRefetchCards();

  const [exportCardFormat, setExportCardFormat] = useState<string>(
    EXPORT_CARD_FORMAT.ID
  );
  const [exportFormat, setExportFormat] = useState<string>(
    EXPORT_FORMAT.NORMAL
  );

  const validation = useSealedValidation(
    cards,
    MIN_CARDS,
    MAX_FACTIONS,
    MAX_HEROES
  );

  // Fonction pour appeler le handler avec des arguments dans n'importe quel ordre
  const handleExportDeck = (specificExport: ExportHandler, label?: string) => {
    if (isSealed && validation && !validation.isValid) {
      for (let index = 0; index < validation.errors.length; index++) {
        const error = validation.errors[index];
        toast.warning(error);
      }
    }

    specificExport({
      cards: cards,
      needFilling: isSealed,
      label: label,
      cardFormat: exportCardFormat,
      exportFormat: exportFormat,
    });
  };

  const handleRefetchCards = () => {
    const uniqueCards = getUniqueFromList(cards);
    refetchCards(uniqueCards);
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      closeModal={closeModal}
      maxWidth="max-w-lg"
      width="w-full"
    >
      {loading ? (
        <Loading label={loadingLabel} isFull />
      ) : (
        <div className="space-y-6">
          <div className="space-y-4">
            <div className="flex items-center space-x-2 text-sm text-darkBlue">
              <span className="font-chillaxBold">
                {t('actions.exportCardFormat')}
              </span>
              <select
                value={exportCardFormat}
                onChange={(event) => setExportCardFormat(event.target.value)}
                className="block shadow sm:text-sm rounded p-0.5 border-2 font-chillaxMedium outline-none bg-lightBlue border-darkBlue"
              >
                {Object.entries(EXPORT_CARD_FORMAT).map(([key, value]) => (
                  <option key={key} value={value}>
                    {t(`export.${key.toLowerCase()}Format`)}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center space-x-2 text-sm text-darkBlue">
              <span className="font-chillaxBold">
                {t('actions.exportFormat')}
              </span>
              <select
                value={exportFormat}
                onChange={(event) => setExportFormat(event.target.value)}
                className="block shadow sm:text-sm rounded p-0.5 border-2 font-chillaxMedium outline-none bg-lightBlue border-darkBlue"
              >
                {Object.entries(EXPORT_FORMAT).map(([key, value]) => (
                  <option key={key} value={value}>
                    {t(`export.${key.toLowerCase()}`)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <Separator />
          <div className="py-1 space-y-2">
            <label className="block text-sm font-chillaxBold text-darkBlue">
              {t('actions.export')}
            </label>
            <div className="flex">
              {exportOptions.map(({ handler, translationKey }, index) => (
                <div className="flex text-xs" key={translationKey}>
                  <Button
                    onClick={() => handleExportDeck(handler, t(translationKey))}
                  >
                    {t(translationKey)}
                  </Button>
                  {index < exportOptions.length - 1 && <SmallSeparator small />}
                </div>
              ))}
            </div>
          </div>
          <Separator />
          <div className="py-1 space-y-2">
            <label className="block text-sm font-chillaxBold text-darkBlue">
              {t('actions.exportExternalTools')}
            </label>
            <div className="grid grid-cols-2 gap-1">
              {exportToolsOptions.map(({ handler, translationKey }) => (
                <div className="flex flex-col text-xs" key={translationKey}>
                  <Button
                    onClick={() => handleExportDeck(handler, t(translationKey))}
                  >
                    {t(translationKey)}
                  </Button>
                </div>
              ))}
            </div>
          </div>
          <Separator />
          <label className="block text-sm font-chillaxBold text-darkBlue">
            {t('refetch.refetchUnique')}
          </label>
          <Button onClick={handleRefetchCards}>
            {t('actions.refetchcard')}
          </Button>
        </div>
      )}
    </ModalTemplate>
  );
};

export default ExportModal;
