import React from 'react';

import { useTranslation } from 'react-i18next';
import { FaPaypal, FaUser } from 'react-icons/fa';
import { FaCoffee } from 'react-icons/fa';
import { GiCardJoker } from 'react-icons/gi';

import Button from '../components/Button';
import Separator from '../components/Separator';
import WhiteContainer from '../components/WhiteContainer';
import Layout from '../parts/Layout';
import { openInNewTab } from '../utils/common';

const Support = () => {
  const { t } = useTranslation();

  return (
    <Layout noSidebar>
      <div className="p-6 space-y-6">
        <div className="flex flex-col items-center">
          <h1 className="text-3xl font-chillaxBold text-center">
            {t('support.title')}
          </h1>
          <p className="font-chillaxMedium">
            {t('support.thanks.description')}
          </p>
        </div>
        <Separator />

        <div>
          <h3 className="text-lg font-chillaxBold">
            {t('support.whySupport.title')}
          </h3>
          <p className="font-chillaxMedium">
            {t('support.whySupport.description')}
          </p>
          <ul className="list-disc list-inside font-chillaxMedium mt-2">
            <li>{t('support.whySupport.points.serverCosts')}</li>
            <li>{t('support.whySupport.points.development')}</li>
            <li>{t('support.whySupport.points.dataUpdates')}</li>
          </ul>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <WhiteContainer fit isSelected>
            <div className="p-4 space-y-4">
              <div>
                <h3 className="text-lg font-chillaxBold">
                  {t('support.howToSupport.cardDonations.title')}
                </h3>
                <p className="font-chillaxMedium">
                  {t('support.howToSupport.cardDonations.description')}
                </p>
              </div>
              <ul className="list-disc list-inside font-chillaxMedium">
                <li>
                  {t('support.howToSupport.cardDonations.points.myCollection')}
                </li>
                <li>
                  {t('support.howToSupport.cardDonations.points.giveaways')}
                </li>
              </ul>
              <Separator />
              <div className="flex flex-col md:flex-row space-y-2 md:space-x-2 md:space-y-0">
                <Button
                  small
                  onClick={() =>
                    openInNewTab('https://altverse.fr/shared/AEIVBB')
                  }
                >
                  <div className="flex items-center space-x-2">
                    <GiCardJoker />
                    <p>
                      {t('support.howToSupport.cardDonations.missingCards')}
                    </p>
                  </div>
                </Button>
                <Button
                  small
                  onClick={() =>
                    openInNewTab(
                      'https://www.altered.gg/fr-fr/profile/Altverseweb_7351'
                    )
                  }
                >
                  <div className="flex items-center space-x-2">
                    <FaUser />
                    <p>{t('support.howToSupport.cardDonations.profileLink')}</p>
                  </div>
                </Button>
              </div>
            </div>
          </WhiteContainer>

          <WhiteContainer fit isSelected>
            <div className="flex flex-col p-4 justify-between h-full">
              <div>
                <h3 className="text-lg font-chillaxBold">
                  {t('support.howToSupport.moneyDonations.title')}
                </h3>
                <p className="font-chillaxMedium">
                  {t('support.howToSupport.moneyDonations.description')}
                </p>
              </div>
              <div className="space-y-4">
                <Separator />
                <div className="flex flex-col md:flex-row space-y-2 md:space-x-2 md:space-y-0">
                  <Button
                    small
                    onClick={() =>
                      openInNewTab('https://buymeacoffee.com/altverse')
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <FaCoffee />
                      <p>
                        {t('support.howToSupport.moneyDonations.buymeacoffee')}
                      </p>
                    </div>
                  </Button>
                  <Button
                    small
                    onClick={() => openInNewTab('https://paypal.me/maucsama')}
                  >
                    <div className="flex items-center space-x-2">
                      <FaPaypal />
                      <p>{t('support.howToSupport.moneyDonations.paypal')}</p>
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </WhiteContainer>
        </div>
        <Separator />
        <div>
          <h3 className="text-lg font-chillaxBold">
            {t('support.finalMessage.title')}
          </h3>
          <p className="font-chillaxMedium">
            {t('support.finalMessage.description')}
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Support;
