import React from 'react';

import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { FaPlus } from 'react-icons/fa';

import Button from '../components/Button';
import Separator from '../components/Separator';

export interface Tab {
  key: string;
  label?: string;
  icon?: IconType;
}

interface TabsProperties<T extends { [key: string]: Tab }> {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  handleAction?: () => void;
  tabs: T;
  isSealed?: boolean;
  small?: boolean;
  bottom?: boolean;
  full?: boolean;
}

const Tabs = <T extends { [key: string]: Tab }>({
  selectedTab,
  setSelectedTab,
  handleAction,
  tabs,
  isSealed,
  small,
  full,
  bottom,
}: TabsProperties<T>) => {
  const { t } = useTranslation();
  return (
    <div
      className={`block w-full overflow-x-scroll ${isSealed ? 'lg:hidden' : ''}`}
    >
      <div className={`md:w-full`}>
        {bottom && <Separator />}
        <div className="flex justify-between items-end">
          <div
            className={`flex w-full items-end lg:px-10 md:space-x-2 ${
              small ? 'space-x-1 px-1' : 'space-x-2 px-2'
            }`}
          >
            {Object.values(tabs).map((tab) => (
              <button
                key={tab.key}
                className={`${
                  small ? 'px-1' : 'px-4'
                } border-4 ${bottom ? 'rounded-b-xl border-t-0' : 'rounded-t-xl border-b-0'} ${full && 'w-full'} py-2 font-chillaxBold text-sm lg:text-base border-darkBlue ${
                  selectedTab === tab.key
                    ? 'bg-darkBlue text-white cursor-default'
                    : 'text-darkBlue hover:bg-lightBlue'
                }`}
                onClick={() => setSelectedTab(tab.key)}
              >
                {tab.label && t(tab.label)}
                {tab.icon && <tab.icon className="inline w-6 h-6" />}
              </button>
            ))}
          </div>
          {handleAction && (
            <Button small top onClick={handleAction}>
              <div className="flex justify-center items-center h-full">
                <FaPlus />
              </div>
            </Button>
          )}
        </div>
        {!bottom && <Separator />}
      </div>
    </div>
  );
};

export default Tabs;
