import {
  CARD_TYPE,
  FACTIONS,
  NUMBER_FILTERS,
  RARITY,
} from '../constants/constants';

export const collectionFilterModes = [
  { value: NUMBER_FILTERS.EQUALS, label: '=' },
  { value: NUMBER_FILTERS.GREATER, label: '>' },
  { value: NUMBER_FILTERS.LESS, label: '<' },
];

export const cardsTypeData = [
  { value: CARD_TYPE.HERO, label: 'gameElements.heroes' },
  { value: CARD_TYPE.CHARACTER, label: 'gameElements.characters' },
  { value: CARD_TYPE.SPELL, label: 'gameElements.spells' },
  { value: CARD_TYPE.PERMANENT, label: 'gameElements.PERMANENT' },
  {
    value: CARD_TYPE.LANDMARK_PERMANENT,
    label: 'gameElements.LANDMARK_PERMANENT',
  },
  {
    value: CARD_TYPE.EXPEDITION_PERMANENT,
    label: 'gameElements.EXPEDITION_PERMANENT',
  },
  { value: CARD_TYPE.TOKEN, label: 'gameElements.token' },
  { value: CARD_TYPE.TOKEN_MANA, label: 'gameElements.tokenMana' },
];

export const cardsTypeDeckData = [
  { value: CARD_TYPE.CHARACTER, label: 'gameElements.characters' },
  { value: CARD_TYPE.SPELL, label: 'gameElements.spells' },
  { value: CARD_TYPE.PERMANENT, label: 'gameElements.PERMANENT' },
];

export const factionData = [
  { value: FACTIONS.AX, label: 'factions.axiom' },
  { value: FACTIONS.BR, label: 'factions.bravos' },
  { value: FACTIONS.LY, label: 'factions.lyra' },
  { value: FACTIONS.MU, label: 'factions.muna' },
  { value: FACTIONS.OR, label: 'factions.ordis' },
  { value: FACTIONS.YZ, label: 'factions.yzmir' },
];

export const visibilityData = [
  { value: 'ALL', label: 'filterTitle.all' },
  { value: 'PUBLIC', label: 'deck.public' },
  { value: 'PRIVATE', label: 'deck.private' },
];

export const legalityData = [
  { value: 'ALL', label: 'filterTitle.all' },
  { value: 'LEGAL', label: 'deck.legal' },
  { value: 'ILLEGAL', label: 'deck.illegal' },
];

export const modeData = [
  {
    value: 'CONSTRUCTED',
    label: 'modes.constructed',
  },
  {
    value: 'SEALED',
    label: 'modes.sealed',
  },
  {
    value: 'DRAFT',
    label: 'modes.draft',
  },
  {
    value: '2VS2',
    label: 'modes.2vs2',
  },
];

export const heroesData = [
  {
    value: 'RIN',
    label: 'heroes.rin',
  },
  {
    value: 'TEIJA',
    label: 'heroes.teija',
  },
  {
    value: 'ARJUN',
    label: 'heroes.arjun',
  },
  {
    value: 'SIERRA',
    label: 'heroes.sierra',
  },
  {
    value: 'TREYST',
    label: 'heroes.treyst',
  },
  {
    value: 'SUBHASH',
    label: 'heroes.subhash',
  },
  {
    value: 'KOJO',
    label: 'heroes.kojo',
  },
  {
    value: 'ATSADI',
    label: 'heroes.atsadi',
  },
  {
    value: 'BASIRA',
    label: 'heroes.basira',
  },
  {
    value: 'FEN',
    label: 'heroes.fen',
  },
  {
    value: 'AURAQ',
    label: 'heroes.auraq',
  },
  {
    value: 'NEVENKA',
    label: 'heroes.nevenka',
  },
  {
    value: 'WARU',
    label: 'heroes.waru',
  },
  {
    value: 'GULRANG',
    label: 'heroes.gulrang',
  },
  {
    value: 'SIGISMAR',
    label: 'heroes.sigismar',
  },
  {
    value: 'AKESHA',
    label: 'heroes.akesha',
  },
  {
    value: 'AFANAS',
    label: 'heroes.afanas',
  },
  {
    value: 'LINDIWE',
    label: 'heroes.lindiwe',
  },
];

export const cardsRarityData = [
  { value: RARITY.COMMON, label: 'gameElements.commons' },
  { value: RARITY.RARE, label: 'gameElements.rares' },
  { value: RARITY.UNIQUE, label: 'gameElements.unique' },
];
